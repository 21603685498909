import { useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import NavBar from "../Components/Navbar";
import ModalForm from "../Components/ModalForm";
import {
  Box,
  Text,
  Heading,
  Button,
  Flex,
  useDisclosure,
  HStack,
  useToast,
  Spinner,
  Checkbox,
} from "@chakra-ui/react";
import axios from "axios";
import IndividualPanel from "../Components/panels/IndividualPanels";
import useAPI from "../hooks/useAPI";
import CustomSpinner from "../Components/CustomSpinner";
import universities from "../data/universities";
import { useAuth } from "../Components/auth/AuthProvider";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../Components/DeleteModal";
import logo from "../assets/logo.jpeg";
import InvoiceModal from "../Components/InvoiceModal";

function IndividualApp() {
  const toast = useToast();
  const { id } = useParams();
  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();
  const {
    isOpen: isOpenInvoice,
    onOpen: onOpenInvoice,
    onClose: onCloseInvoice,
  } = useDisclosure();
  const { token } = useAuth();
  const navigate = useNavigate();

  const { data: studentInfo, setData: setStudentInfo, loading: studentInfoLoading } = useAPI(
    `/applications/${id}`
  );

  const { data: finances, loading: financesLoading } = useAPI(
    `/applications/${id}/finances`
  );

  const { data: invoice, loading: invoiceLoading } = useAPI(
    `/display_invoices/${id}/file-url`
  );

  const statusLabels = {
    open: "Open",
    pending: "Pending",
    approved: "Approved",
    denied: "Denied",
    completed: "Completed",
    prospective_scholarship: "Prospective Scholarship",
    retroactive_scholarship: "Retroactive Scholarship",
    discontinued_studies: "Discontinued Studies"
  };

  const modalData = [
    {
      key: "first_name",
      label: "First Name",
      value: studentInfo?.first_name,
      type: "text",
    },
    {
      key: "last_name",
      label: "Last Name",
      value: studentInfo?.last_name,
      type: "text",
    },
    {
      key: "university",
      label: "University",
      value: studentInfo?.university?.id,
      type: "select",
      options: universities,
    },
    {
      key: "mat_number",
      label: "MAT Number",
      value: studentInfo?.mat_number,
      type: "text",
    },
    {
      key: "status",
      label: "Status",
      value: studentInfo?.status,
      type: "select",
      options: statusLabels,
    },
    {
      key: "graduation_date",
      label: "Graduation Date",
      value: studentInfo?.graduation_date?.split("T")[0],
      type: "date",
    },
    {
      key: "email",
      label: "Email",
      value: studentInfo?.email,
      type: "text",
    },
    {
      key: "phone_number",
      label: "Phone Number",
      value: studentInfo?.phone_number,
      type: "text",
    },
    {
      key: "start_date",
      label: "Invoice Start",
      value: studentInfo?.start_date?.split("T")[0],
      type: "date",
    },
    {
      key: "tuition_amount",
      label: "Tuition Amount",
      value: studentInfo?.tuition_amount,
      type: "text",
    },
    {
      key: "arrears_amount",
      label: "Arrears Amount",
      value: studentInfo?.arrears_amount,
      type: "text",
    },
  ];

  const handleEdit = (data) => {
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/applications/${id}`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        onCloseEdit();
        toast({
          title: "Application successfully edited",
          position: "top",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        onCloseEdit();
        toast({
          title: "Failed to edit application",
          position: "top",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        console.error("Error editing application: ", err);
      });
  };

  const handleDelete = () => {
    axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/applications/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        onCloseDelete();
        toast({
          title: "Application successfully deleted",
          position: "top",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        navigate("/applications");
      })
      .catch((err) => {
        onCloseDelete();
        toast({
          title: "Failed to delete application",
          position: "top",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        console.error("Error editing application: ", err);
      });
  };

  const handleMatch = (matcher) => {
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/applications/${id}/match?matcher=${matcher}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        toast({
          title: "Failed to match application",
          position: "top",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handleInvoice = async (file, fileName) => {
    if (!file) {
      toast({
        title: "Invoice must be uploaded.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const newFormData = new FormData();
    newFormData.append('id', id);
    newFormData.append('file', file, fileName);

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/display_invoices/upload`, newFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        toast({
          title: "File uploaded successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        onCloseInvoice();
      }
    } catch (error) {
      toast({
        title: "File upload failed.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <NavBar
        activeName="Applications"
        onChange={(label) => console.log(label)}
      />
      {(studentInfoLoading || financesLoading || invoiceLoading) && (
        <Box pt={"600px"}>
          <CustomSpinner />
        </Box>
      )}
      {!studentInfoLoading && !financesLoading && !invoiceLoading && studentInfo && finances && invoice && (
        <Box m={6}>
          <Flex align="center" justify="space-between" mb={4}>
            <Heading as="h1" size="xl">
              {studentInfo.first_name} {studentInfo.last_name}
            </Heading>
            <HStack>
              <Button size="md" onClick={onOpenEdit}>
                Edit Info
              </Button>
              <Button size="md" colorScheme="red" onClick={onOpenDelete}>
                Delete Application
              </Button>
              <Button size="md" colorScheme="blue" onClick={onOpenInvoice}>
                Upload Invoice
              </Button>
            </HStack>
          </Flex>
          <ModalForm
            isOpen={isOpenEdit}
            onClose={onCloseEdit}
            onSubmit={handleEdit}
            title="Edit Student Info"
            data={modalData}
          />
          <DeleteModal
            isOpen={isOpenDelete}
            onClose={onCloseDelete}
            onDelete={handleDelete}
            title="Are you sure you want to delete this application?"
          />
          <InvoiceModal
            isOpen={isOpenInvoice}
            onClose={onCloseInvoice}
            onSubmit={handleInvoice}
            title="Upload Invoice"
          />

          <Flex direction={{ base: "column", md: "row" }}>
            <Box flex="0.3">
              <Checkbox
                mr={4}
                colorScheme="brand"
                isChecked={studentInfo.matched_by_mafugi}
                onChange={(e) => {
                  handleMatch("mafugi");
                  setStudentInfo({ ...studentInfo, matched_by_mafugi: !studentInfo.matched_by_mafugi });
                }}
              >
                <strong>Matched by Mafugi</strong>
              </Checkbox>
              <Checkbox
                mb={4}
                colorScheme="brand"
                isChecked={studentInfo.matched_by_john}
                onChange={(e) => {
                  handleMatch("john");
                  setStudentInfo({ ...studentInfo, matched_by_john: !studentInfo.matched_by_john });
                }}
              >
                <strong>Matched by John</strong>
              </Checkbox>
              <Text fontSize="lg">
                <strong>University:</strong> {studentInfo.university?.name}
              </Text>
              <Text fontSize="lg">
                <strong>MAT Number:</strong> {studentInfo.mat_number}
              </Text>
              <Text fontSize="lg">
                <strong>Status:</strong> {statusLabels[studentInfo.status]}
              </Text>
              <Text fontSize="lg">
                <strong>Graduation Date:</strong> {studentInfo.graduation_date?.split("T")[0]}
              </Text>
              <Text fontSize="lg">
                <strong>Email:</strong> {studentInfo.email}
              </Text>
              <Text fontSize="lg">
                <strong>Phone:</strong> {studentInfo.phone_number}
              </Text>
              <Text fontSize="lg">
                <strong>Date Applied:</strong> {studentInfo.date_applied?.split("T")[0]}
              </Text>
              <Text fontSize="lg">
                <strong>Invoice Start:</strong> {studentInfo.start_date?.split("T")[0]}
              </Text>
              <Text fontSize="lg">
                <strong>Tuition Amount:</strong> {studentInfo.tuition_amount}
              </Text>
              <Text fontSize="lg">
                <strong>Arrears Amount:</strong> {studentInfo.arrears_amount}
              </Text>
              <Text fontSize="lg">
                <strong>Application Fee:</strong> {finances.application_fee}
              </Text>
              <Text fontSize="lg">
                <strong>Advance Total:</strong> {finances.total_advances}
              </Text>
              <Text fontSize="lg">
                <strong>Membership Fee Total:</strong> {finances.total_membership_fees}
              </Text>
              <Text fontSize="lg">
                <strong>Total Deposited:</strong> {finances.total_deposited}
              </Text>
              <Text fontSize="lg">
                <strong>Total Expected:</strong> {finances.total_expected}
              </Text>
              <Text fontSize="lg">
                <strong>Deposited - Expected:</strong> {finances.deposited_minus_expected}
              </Text>
              <Text fontSize="lg">
                <strong>Percent of Total Paid Off:</strong> {(finances.total_deposited/(finances.total_deposited + finances.balance) * 100).toFixed(2) + "%"}
              </Text>
              <Text fontSize="lg">
                <strong>Balance:</strong> {finances.balance}
              </Text>
              <Text fontSize="lg" mb={8}>
                <strong>Days Since Last Deposit:</strong> {finances.days_since_last_deposit}
              </Text>
            </Box>
            <Box flex="0.6" ml={8}>
              <Box mx="auto" height="500px">
                {invoice.file_url === "logo" ? (
                  <img src={logo} alt="Invoice" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                ) : (
                  <img src={invoice.file_url} alt="Invoice" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                )}
              </Box>
            </Box>
          </Flex>
          <IndividualPanel id={id} />
        </Box>
      )}
    </>
  );
}

export default IndividualApp;